@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

.carousel__dot--selected div {
  background-color: #c1c1c1;
}
#gradient-text:hover {
  background: -webkit-linear-gradient(#f65e1d, #747474);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#header-gradient-text {
  background: -webkit-linear-gradient(#f65e1d, #747474);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.box1 {
  display: flex;
  position: relative;
  animation-delay: 2s;
  animation: example 3s infinite;
  animation-fill-mode: backwards;
  margin-top: 80px;
  width: "635";
  height: "602";
  /* background-color: black; */
}
@keyframes example {
  0% {
    top: 60px;
  }
  40% {
    top: 0px;
  }
  60% {
    top: 0px;
  }
  100% {
    top: 60px;
  }
}

.shadow {
  display: flex;
  position: fixed;
  animation-delay: 2s;
  justify-content: center;
  animation: exampleshadow 3s infinite;
  animation-fill-mode: forwards;
  margin-top: 120px;
  /* margin: 120px auto; */
  opacity: 0.6;
  /* width: "100";
  height: "50"; */
  width: 350px;
  height: 150px;
}

@keyframes exampleshadow {
  0% {
    /* width: 400px; */

    height: 150px;
    opacity: 0.2;
  }
  40% {
    /* width: 100px; */
    height: 45px;

    opacity: 0;
  }

  60% {
    /* width: 100px; */
    height: 45px;
    opacity: 0;
  }
  100% {
    height: 150px;
    opacity: 0.2;
    /* width: 400px; */
  }
}

button:hover {
  background: linear-gradient(
    -45deg,
    #f7931f,
    #f07128,
    #f05a28,
    #e25424,
    #f05a28,
    #f07128,
    #f7931f,
    #f7931f,
    #f07128,
    #f05a28,
    #e25424,
    #f05a28,
    #f07128,
    #f7931f
  );
  animation: gradient 5s ease-in infinite;
  background-size: 400% 400%;
  animation-direction: normal;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.partnerCircle {
  opacity: 0.06 !important;
}

.note1 {
}

.note2 {
}
.note3 {
}

input:focus-visible,
textarea:focus-visible {
  /* border: 2px solid #f05a28; */
  outline-color: #f05a28d8;
}

/* .file-upload-input {
  display: none;
} */

/* input::-webkit-file-upload-button {
  font-size: 12px;
  margin-left: 330px;

  align-self: center;
  width: 80px;
  height: 24px;
  color: #404041;
  padding: 5px;

  background-color: transparent;
  border: thin solid #e25424;
  border-radius: 5px;
  position: absolute;
} */

/* input::file-selector-button {
  font-size: 12px;
  display: none;

  align-self: center;
  width: 80px;
  height: 24px;
  color: #404041;
  padding: 5px;

  background-color: transparent;
  border: thin solid #e25424;
  border-radius: 5px;
  position: absolute;
  background-color: red;
} */

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

select {
  background-color: white;
  border-radius: 10px;
}

select:focus {
  background-color: white;
}

select option {
  border-bottom: 1px solid gray;
}

.multipleLineEllipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}


.sliderbutton:hover{
  /* background-color: transparent!important; */
  -webkit-tap-highlight-color: transparent;
  background-color: white;

}

.sliderbutton:active{
  /* background-color: transparent!important; */
  -webkit-tap-highlight-color: transparent;
  background-color: white; 

}